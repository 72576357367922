import { Alert, Collapse, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomNotification, Input } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteLayer } from 'services/message.service';
interface DeleteLayerModalProps {
    modal: any;
    onSuccess?: () => void;
    layerId?: number | null;
}

const DeleteLayerModal: React.FC<DeleteLayerModalProps> = ({ modal, onSuccess, layerId }) => {
    let { id: paramId } = useParams();
    const idToDelete = layerId ?? paramId;
    const handleOnClose = () => {
        modal.close();
    };

    const { request, loading } = useApi({
        api: deleteLayer,
    });

    const deleteLayers = async (id: any) => {
        const res = await request({
            id,
        });
        const { error } = res;
        if (!error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: <p>Layers deleted</p>,
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: error.message,
            });
        }
    };

    const handleFormFinish = async () => {
        if (idToDelete) {
            deleteLayers(idToDelete);
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'No valid ID provided for deletion.',
            });
        }
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Delete Layer"
            onOk={handleFormFinish}
            okText="Yes"
            okButtonProps={{
                danger: true,
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <p>Are you sure you want to delete the layer? This action cannot be undone.</p>
        </Modal>
    );
};

export default DeleteLayerModal;
