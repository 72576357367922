import React, { useCallback, useContext, useRef, useState } from 'react';
import { Circle, CircleMarker, MapContainer, Marker, Polygon, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { latLngToCell } from 'h3-js';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import DemandHotspotsFilter from './DemandHotspotsFilter';
import { Input, Text } from 'components/basic';
import { LatLngExpression } from 'leaflet';
import { useMemo } from '@storybook/addons';
import useApi from 'hooks/useApi';
import { getDemandHotspots, getDemandPickups } from 'services/message.service';
import useMount from 'hooks/useMount';
import moment from 'moment';

const DemandHotspotsPage = () => {
    // for more sample of themes, https://leaflet-extras.github.io/leaflet-providers/preview/index.html
    const center = { lat: 14.5442586, lng: 121.0170126 };

    const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

    const [latitude, setLatitude] = useState(center.lat);
    const [longitude, setLongitude] = useState(center.lng);
    const [radius, setRadius] = useState(5);
    const [selectedServiceZone, setSelectedServiceZone] = useState('MNL');
    const [selectedServiceId, setSelectedServiceId] = useState('passenger_bike');
    const [selectedTimestamp, setSelectedTimestamp] = useState('');
    const [demandHotspotsResult, setDemandHotspotsResult] = useState<any>([]);
    const [demandPickupsResult, setDemandPickupsResult] = useState<any>([]);

    const apiKey = process.env.REACT_APP_STADIA_API_KEY;

    const {
        request: getDemandHotspotsRequest,
        result: getDemandHotspotsResult,
        loading: getDemandHotspotsLoading,
    } = useApi({
        api: getDemandHotspots,
    });

    const {
        request: getDemandPickupsRequest,
        result: getDemandPickupsResult,
        loading: getDemandPickupsLoading,
    } = useApi({
        api: getDemandPickups,
    });

    const fetchDemandPickups = useCallback(
        async (body: any) => {
            try {
                const result = await getDemandPickupsRequest(body);
                var d = result.data.pickups;

                d.forEach((pickup: any) => {
                    var color = 'black';

                    demandHotspotsResult.forEach((demandHotspot: any) => {
                        if (pickup.c == demandHotspot.id) {
                            color = demandHotspot.color;
                        }
                    });

                    pickup.color = color;
                });

                setDemandPickupsResult(d);
            } catch (error) {
                throw error;
            }
        },
        [getDemandPickupsRequest, demandHotspotsResult],
    );

    const fetchDemandHotspots = useCallback(
        async (body: any) => {
            try {
                const result = await getDemandHotspotsRequest(body);
                var d = result.data.locations;

                const demandHotspots = d.map((c: any) => ({
                    ...c,
                    key: c.id,
                    color: '#' + Math.floor(Math.random() * 16777215).toString(16),
                }));

                setDemandHotspotsResult(demandHotspots);
                fetchDemandPickups(body);
            } catch (error) {
                throw error;
            }
        },
        [getDemandHotspotsRequest, fetchDemandPickups],
    );

    const onFilterSubmit = useCallback(
        (filter: any) => {
            setSelectedServiceZone(filter.serviceZone);
            setSelectedTimestamp(filter.dataAt);
            fetchDemandHotspots({
                serviceZone: filter.serviceZone,
                serviceId: selectedServiceId,
                dataAt: filter.dataAt,
            });
        },
        [setSelectedServiceZone, setSelectedTimestamp, fetchDemandHotspots, selectedServiceId],
    );

    const handleLatitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLatitude(Number(e.target.value));
    };

    const handleLongitudeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLongitude(Number(e.target.value));
    };

    const handleRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRadius(Number(e.target.value));
    };

    return (
        <ListLayout
            title="Demand Hotspots"
            searchComponent={<DemandHotspotsFilter onFilterSubmit={onFilterSubmit} loading={false} />}
            actionComponent={
                <div className="">
                    <div className="flex flex-1 items-center gap-2">
                        <div className="block">
                            <Text type="label">Pin Driver</Text>
                            <div className="flex flex-row w-80 gap-2">
                                <Input
                                    className="w-56"
                                    value={latitude}
                                    onChange={handleLatitudeChange}
                                    placeholder="Enter latitude"
                                />
                                <Input
                                    className="w-56"
                                    value={longitude}
                                    onChange={handleLongitudeChange}
                                    placeholder="Enter longitude"
                                />
                            </div>
                        </div>
                        <div className="block">
                            <Text type="label">Radius (kms)</Text>
                            <div className="flex flex-row w-20 gap-2">
                                <Input value={radius} onChange={handleRadiusChange} placeholder="Enter Radius" />
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 text-right">Use these controls to visualize a driver's location.</div>
                </div>
            }
        >
            <div className="w-full block" style={{ height: '700px' }}>
                <MapContainer
                    center={[latitude, longitude]}
                    zoom={13}
                    scrollWheelZoom={true}
                    className="w-full h-full border border-2 border-sky-500 border-solid"
                >
                    <TileLayer
                        minZoom={0}
                        maxZoom={20}
                        url={`https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png?api_key=${apiKey}`}
                    />

                    <Marker position={[latitude, longitude]}>
                        <Popup>Your driver is here.</Popup>
                    </Marker>

                    <Circle
                        center={[latitude, longitude]}
                        radius={radius * 1000}
                        stroke={false}
                        interactive={false}
                        pathOptions={{ color: 'blue' }}
                    />

                    {demandHotspotsResult &&
                        demandHotspotsResult.map((hotspot: any) => (
                            <CircleMarker
                                key={hotspot.id}
                                center={[hotspot.latitude, hotspot.longitude]}
                                pathOptions={{ color: 'red' }}
                                radius={3}
                                weight={1}
                            >
                                <Popup>
                                    <div>
                                        <strong>Demand Hotspot ID:</strong> {hotspot.id}
                                        <br />
                                        <strong>Timestamp:</strong> {moment(hotspot.timestamp).format(dateFormat)}
                                    </div>
                                </Popup>
                            </CircleMarker>
                        ))}

                    {demandPickupsResult &&
                        demandPickupsResult.map((pickup: any) => (
                            <CircleMarker
                                key={pickup.id}
                                center={[pickup.a, pickup.b]}
                                pathOptions={{ color: pickup.color }}
                                radius={2}
                                weight={1}
                            >
                                <Popup>
                                    <div>
                                        <strong>Demand Hotspot ID: {pickup.c}</strong>
                                        {pickup.a}, {pickup.b}
                                    </div>
                                </Popup>
                            </CircleMarker>
                        ))}
                </MapContainer>
            </div>
        </ListLayout>
    );
};

export default DemandHotspotsPage;
