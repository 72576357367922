import { Alert, Collapse, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CustomNotification, Input } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteLayer, deleteZone } from 'services/message.service';
interface DeleteZoneModalProps {
    modal: any;
    onSuccess?: () => void;
    layerId?: number | null;
    zoneId?: any;
}

const DeleteZoneModal: React.FC<DeleteZoneModalProps> = ({ modal, onSuccess, zoneId }) => {
    let { id: paramId } = useParams();
    const handleOnClose = () => {
        modal.close();
    };

    const { request, loading } = useApi({
        api: deleteZone,
    });

    const deleteLayers = async (id: any, zoneId: any) => {
        const res = await request({
            id,
            zoneId,
        });
        const { error } = res;
        if (!error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: <p>Zone deleted</p>,
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: error.message,
            });
        }
    };

    const handleFormFinish = async () => {
        if (paramId && zoneId) {
            deleteLayers(paramId, zoneId);
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'No valid ID provided for deletion.',
            });
        }
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Delete Layer"
            onOk={handleFormFinish}
            okText="Yes"
            okButtonProps={{
                danger: true,
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <p>Are you sure you want to delete the Zone? This action cannot be undone.</p>
        </Modal>
    );
};

export default DeleteZoneModal;
