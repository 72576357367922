import React, { useState, useCallback } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { Button } from 'antd';
import { CustomNotification } from 'components/basic';
import { Input } from 'components/basic';
const mapContainerStyle = {
    width: '100%',
    height: '85vh',
};

const center = {
    lat: 14.6091,
    lng: 121.0223,
};

const libraries: any[] = ['places'];
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

interface MapComponentProps {
    onPolygonUpdate: (coords: google.maps.LatLng[] | null) => void;
    onPlaceNameUpdate: (name: string) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({ onPolygonUpdate, onPlaceNameUpdate }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
    const [polygonPath, setPolygonPath] = useState<google.maps.LatLng[]>([]);
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
    const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null);
    const [mapKey, setMapKey] = useState(0);
    const [inputValue, setInputValue] = useState('');

    const onMapLoad = useCallback(
        (map: google.maps.Map) => {
            setMap(map);
            setAutocompleteService(new google.maps.places.AutocompleteService());
            setPlacesService(new google.maps.places.PlacesService(map));

            map.addListener('click', (event: google.maps.MapMouseEvent) => {
                console.log(event, 'test event');
                if (event.latLng && !polygon) {
                    setPolygonPath(prev => {
                        const newPath = [...prev, event.latLng].filter(
                            (point): point is google.maps.LatLng => point !== null,
                        );

                        const newPolygon = new google.maps.Polygon({
                            paths: newPath,
                            strokeColor: '#FF0000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#FF0000',
                            fillOpacity: 0.35,
                            editable: true,
                            draggable: false,
                        });
                        newPolygon.setMap(map);
                        setPolygon(newPolygon);

                        onPolygonUpdate(newPath);

                        return newPath;
                    });
                } else if (polygon) {
                    CustomNotification({
                        type: 'error',
                        message: 'Polygon already exists!',
                        description: 'Please clear the current polygon before drawing a new one.',
                    });
                }
            });
        },
        [polygon, onPolygonUpdate],
    );

    const onPlaceChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        setInputValue(input);
        if (autocompleteService) {
            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setPredictions(predictions || []);
                }
            });
        }
    };

    const onSelectPlace = (placeId: string) => {
        if (placesService) {
            placesService.getDetails({ placeId }, (place, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place &&
                    place.geometry &&
                    place.geometry.location
                ) {
                    setSelectedPlace(place);
                    setMarkerPosition(place.geometry.location);
                    setPredictions([]);

                    if (map) {
                        const location = place.geometry.location;
                        if (location) {
                            map.panTo(location);
                            map.setZoom(14);
                        }
                    }
                    onPlaceNameUpdate(place.name || 'Unknown Place');
                }
            });
        }
    };

    const clearPolygon = () => {
        if (polygon) {
            polygon.setMap(null);
            setPolygon(null);
            setPolygonPath([]);
            setMapKey(prevKey => prevKey + 1);
            setInputValue('');
            onPolygonUpdate(null);
        }
    };

    if (!isLoaded) return <div>Loading...</div>;
    console.log(predictions);
    return (
        <div style={{ position: 'relative' }}>
            <GoogleMap key={mapKey} mapContainerStyle={mapContainerStyle} center={center} zoom={10} onLoad={onMapLoad}>
                <Marker position={center} />
                {markerPosition && <Marker position={markerPosition} />}
            </GoogleMap>
            <div style={{ position: 'absolute', top: '-93px', left: '0px', zIndex: 1 }}>
                <Input
                    type="text"
                    placeholder="Search Location"
                    style={{ width: '300px', padding: '5px' }}
                    className="rounded border"
                    value={inputValue}
                    onChange={onPlaceChanged}
                    onClick={() => {
                        const input = inputValue;
                        if (autocompleteService) {
                            autocompleteService.getPlacePredictions({ input }, (predictions, status) => {
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    setPredictions(predictions || []);
                                }
                            });
                        }
                    }}
                />
                <Button
                    disabled={!polygon}
                    onClick={clearPolygon}
                    style={{
                        marginLeft: '0.5rem',
                        padding: '5px',
                        height: 'auto',
                    }}
                    danger={!!polygon}
                >
                    Clear Polygon
                </Button>
                {inputValue.length > 0 && predictions.length > 0 && (
                    <div className="bg-white h-auto overflow-auto py-2 rounded drop-shadow w-[300px]">
                        {predictions.map(prediction => (
                            <div
                                className="hover:bg-slate-100 px-4 py-1"
                                key={prediction.place_id}
                                onClick={() => onSelectPlace(prediction.place_id)}
                                style={{ cursor: 'pointer' }}
                            >
                                {prediction.description}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MapComponent;
