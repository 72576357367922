import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { Button, DatePicker, DatePickerProps, Select } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import useMount from 'hooks/useMount';
import { Input, Text } from 'components/basic';

interface DemandHotspotsFilterProps {
    onFilterSubmit: (values: { serviceZone: string; dataAt: string }) => void;
    loading: boolean | undefined;
}

const DemandHotspotsFilter: React.FC<DemandHotspotsFilterProps> = ({ onFilterSubmit, loading }) => {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

    const [selectedServiceZone, setSelectedServiceZone] = useState('MNL');
    const [selectedTimestamp, setSelectedTimestamp] = useState(moment.utc().format());

    const handleFilterSubmit = () => {
        onFilterSubmit({
            serviceZone: selectedServiceZone,
            dataAt: selectedTimestamp,
        });
    };

    const handleFilterReset = () => {
        setSelectedServiceZone('MNL');
        setSelectedTimestamp(moment.utc().format());
        onFilterSubmit({
            serviceZone: 'MNL',
            dataAt: moment().utc().format(),
        });
    };

    const handleDatePickerChange = (date: moment.Moment | null, dateString: string) => {
        setSelectedTimestamp(moment.utc(dateString).format());
    };

    return (
        <div>
            <div className="flex items-end gap-2">
                <div className="flex flex-1 items-center gap-2">
                    <div className="block w-full">
                        <Text type="label">Service Zone</Text>
                        <Select
                            className="w-full"
                            defaultActiveFirstOption
                            value={selectedServiceZone}
                            onChange={setSelectedServiceZone}
                            options={[
                                { value: '', label: 'All' },
                                { value: 'MNL', label: 'MNL' },
                                { value: 'BCD', label: 'BCD' },
                                { value: 'BTG', label: 'BTG' },
                                { value: 'CDO', label: 'CDO' },
                                { value: 'CEB', label: 'CEB' },
                                { value: 'DVO', label: 'DVO' },
                                { value: 'ILO', label: 'ILO' },
                                { value: 'PPG', label: 'PPG' },
                            ]}
                        />
                    </div>
                    <div className="block">
                        <Text type="label">Select Date & Time</Text>
                        <DatePicker
                            onChange={handleDatePickerChange}
                            defaultValue={moment()}
                            showTime
                            className="w-56"
                        />
                    </div>
                </div>
                <div className="flex items-center gap-1">
                    <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                        Apply Filter
                    </Button>
                    <Button onClick={handleFilterReset} disabled={loading}>
                        Clear Filters
                    </Button>
                </div>
            </div>
            <div className="mt-2">
                The data in the map shows the last generated Demand Hotspots during the timestamp that is provided in
                the filter.
            </div>
        </div>
    );
};

export default DemandHotspotsFilter;
